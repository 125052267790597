.about-container {
    font-size: 1.8rem;
    line-height: 2.9rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 10rem;
}

.about-content {
    margin-top: 1rem;
}