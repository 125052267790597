
.designs-container {
    margin-top: 10rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5%
}

.design-item {
    width: 30%;
    margin-top: 30px;
}

@media only screen and (max-width: 770px) {
    .designs-container {
        margin-top: 5rem;
    }
}

@media only screen and (max-width: 510px) {
    .designs-container {
        margin-top: 2rem;
    }
}