.footer-information-container {
    font-size: 1.6rem;
    line-height: 2.9rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10rem;
}

.footer-information-content {
    display: flex;
    flex-direction: column;
    width: 25%;
}

@media (max-width: 600px) {
    .footer-information-content {
        margin-top: 15rem;
    }
}
