.piece-details {
    position: relative;
    display: flex;
}
.piece-details-images-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.piece-details-image {
    width: 100%;
    margin-top: 60px;
}
.piece-details-image img {
    width: 100%;
    object-fit: contain;
}
.piece-details-description {
    position: sticky;
    padding: 20% 0 10% 15%;
    align-self: flex-start;
    top: 0;
    overflow-y: auto;
    font-size: 1.6rem;
    font-style: italic;
}
