.navigation-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-transform: uppercase;
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    flex: min-content;
}
.navigation-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 65%;
}
.navigation-button:hover {
    color: #777;
}
.navigation-button {
    cursor: pointer;
    text-decoration: none;
    color: #000;
    font-size: 3rem;
    margin-left: 3rem;
    text-align: right;
}

@media only screen and (max-width: 982px) {
    .navigation-button {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 760px) {
    .navigation-button {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 577px) {
    .navigation-button {
        margin-left: 1rem;
    }
}