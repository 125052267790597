.pieces-container {
    margin-top: 10rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 5%;
}
.pieces-item {
    width: 30%;
    margin-top: 2rem;
}
.piece-container img {
    width: 100%;
    object-fit: contain;
}
.piece-container {
    width: 100%;
    margin-bottom: 30px;
    position: relative;
    text-decoration: none;
}
.piece-img {
    height: 200px;
    width: 150px;
}
.piece-description {
    padding-left: 1rem;
    padding-top: 1rem;
    width: 100%;
    height: 30%;
    text-decoration: none;
    font-size: 1.4rem;
    font-style: italic;
    color: #000;
}



@media only screen and (max-width: 770px) {
    .pieces-container {
        margin-top: 5rem;
    }
}

@media only screen and (max-width: 510px) {
    .pieces-container {
        margin-top: 2rem;
    }
}