*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
}

body {
  font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
  font-weight: 400;
  line-height: 1.7;
  color: #777;
  padding: 3rem 9rem;
}


@media (max-width: 755px) {
  body {
    padding: 3rem 3rem;
  }
}


@media (max-width: 350px) {
  body {
    padding: 2rem 1rem;
  }
}
